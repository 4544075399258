
<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/cplogo.png" alt="" height="72" width="72" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/cplogo.png" alt="" height="72" width="72" />
          </span>
        </a>
      
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/cplogo.png" alt="" height="72" width="72" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/cplogo.png" alt="" height="72" width="72" />
          </span>
        </a>
      </div>
      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <div class="header-item d-none d-lg-flex align-items-center gap-1 btn">
        <span class="badge bg-danger fs-6 fw-bold py-1 px-2">{{
          topbarCounts?.pending_reservations
        }}</span>
        <a class="text-dark h6 m-0" [routerLink]="['/reservations']">
          {{ "Reservations" | translate }}
        </a>
      </div>

      <!-- <div class="header-item d-none d-lg-flex align-items-center gap-1 btn">
        <span class="badge bg-danger fs-6 fw-bold py-1 px-2">{{
          topbarCounts?.verification_requests
        }}</span>
        <a class="text-dark h6 m-0" [routerLink]="['/join-requests']">
          {{ "Joining Requests" | translate }}
        </a>
      </div> -->

      <div class="header-item d-none d-lg-flex align-items-center gap-1 btn">
        <span class="badge bg-danger fs-6 fw-bold py-1 px-2">{{
          topbarCounts?.refund_requests
        }}</span>
        <a class="text-dark h6 m-0" [routerLink]="['/refund-managment']">
          {{ "Refund Management" | translate }}
        </a>
      </div>

      <div class="header-item d-none d-lg-flex align-items-center gap-1 btn">
        <span class="badge bg-danger fs-6 fw-bold py-1 px-2">{{
          topbarCounts?.payouts_requests
        }}</span>
        <a class="text-dark h6 m-0" [routerLink]="['/payouts-managment']">
          {{ "Owners Payouts" | translate }}
        </a>
      </div>

      <div class="header-item d-none d-lg-flex align-items-center gap-1 btn">
        <span class="badge bg-danger fs-6 fw-bold py-1 px-2">{{
          topbarCounts?.new_properties
        }}</span>
        <a
          class="text-dark h6 m-0"
          [routerLink]="['/properties']"
          [queryParams]="{ admin_approval: 0 }"
        >
          {{ "New Products" | translate }}
        </a>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            *ngIf="flagvalue !== undefined"
            src="{{ flagvalue }}"
            alt="Header Language"
            height="22"
          />
          <span class="ms-1">{{ countryName }}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{ valueset }}"
            alt="Header Language"
            height="22"
          />
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="me-1"
              height="22"
            />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            [src]="currentCurrency?.image"
            alt="Header Language"
            height="22"
          />
          <span class="ms-1">{{ currentCurrency?.currency_code_en }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of currenciesList"
            (click)="setCurrency(item)"
          >
            <img
              src="{{ item.image }}"
              alt="user-image"
              class="me-1"
              height="22"
            />
            <span class="align-middle">{{ item.currency_name }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle
        >
          <i class="bx bx-bell bx-tada"></i>
          <span
            class="badge bg-danger rounded-pill"
            *ngIf="unreadNotifications$ | async as unreadNotifications"
            >{{ unreadNotifications }}</span
          >
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 fw-bold">{{ "Notifications" | translate }}</h6>
              </div>
              <div class="col-auto">
                <div class="p-2 border-top text-center">
                  <a
                    class="btn btn-sm btn-dark btn-block text-center"
                    href="javascript:void(0)"
                    (click)="markAllNotificationsAsRead()"
                  >
                    <i class="mdi mdi-arrow-down-circle me-1"></i>
                    {{ "Read All" | translate }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ngx-simplebar
            style="position: relative; height: 230px"
            *ngIf="allNotifications.length"
          >
            <a
              class="text-reset notification-item"
              *ngFor="let notification of allNotifications"
              (click)="
                notificationTypes(
                  notification?.action_type,
                  notification?.action_id,
                  notification.id
                )
              "
            >
              <div
                class="media mt-2"
                [ngStyle]="{
                  'background-color': !notification.read_at ? '#000' : ''
                }"
              >
                <div class="avatar-xs me-3">
                  <img
                    [src]="notification?.image"
                    class="avatar-title rounded-circle"
                  />
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1 text-dark">
                    {{ notification.title }}
                  </h6>
                  <div class="font-size-12 text-info">
                    <p class="mb-1">
                      {{ notification.body }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ notification.created_at }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <ngx-simplebar
            style="position: relative; height: 230px"
            *ngIf="!allNotifications.length"
          >
            <a href="javascript: void(0);" class="text-reset notification-item">
              <h3 class="text-center">
                {{ "No New Notifications" | translate }}
              </h3>
            </a>
          </ngx-simplebar>

          <div class="p-2 border-top text-center" *ngIf="seeMore">
            <a
              class="btn btn-sm btn-dark btn-block text-center"
              href="javascript:void(0)"
              (click)="loadMoreNotifications()"
            >
              <i class="mdi mdi-arrow-down-circle me-1"></i>
              {{ "Show More" | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/Profile.jpg"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1"> {{ currentuser }} </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a
            class="dropdown-item d-block"
            [routerLink]="['/settings']"
            *ngIf="canViewSettings"
            ><i class="bx bx-wrench font-size-16 align-middle me-1"></i
            >{{ "Settings" | translate }}
          </a>

          <div class="dropdown-divider" *ngIf="canViewSettings"></div>
          <a
            class="dropdown-item text-dark"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-dark"
            ></i>
            {{ "Logout" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
